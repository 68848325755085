// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/maintenance_background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.maintenance-mask[data-v-3fb06f93] {\n    position: fixed;\n    z-index: 9998;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    -moz-transition: opacity 0.3s ease;\n    transition: opacity 0.3s ease;\n    background-color: rgba(0, 0, 0, 0.5);\n}\n.maintenance-body[data-v-3fb06f93] {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 1080px;\n    width: 1920px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 100% 100%;\n    z-index: 9998;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
