(function () {
  const template = document.createElement("template");
  template.innerHTML = `
        <style>
        div {
            position: relative;
            top: 25%;
            width: fit-content;
            height: 2rem;
            text-align: center;
            margin: auto;
            margin-top: 0;
            z-index: 2;
            font-size: 3.75rem;
            font-weight: bold;
            font-family: sans-serif;
            padding-top: 0rem;
            padding-bottom: 0rem;
            background: transparent;
        }
        canvas#responsive-canvas {
            width: 100%;
            height: 95%;
            position: relative;
            top: 0px;
            margin: auto;
            z-index: 1;
            background: black;
          }
        </style>
        <canvas
        id="responsive-canvas"
        style="
            touch-action: none;
            cursor: inherit;
        "
        >
        </canvas>
    </div>
    `;
  class AppPlayBoard extends HTMLElement {
    static get observedAttributes() {
      return ["startGame", "stopGame", "drawVisible"];
    }
    constructor() {
      super();
      this.attachShadow({ mode: "open" });
      // Adds a template clone into shadow root.
      this.shadowRoot.appendChild(template.content.cloneNode(true));
      this._canvas = this.shadowRoot.querySelector("canvas");
    }
    connectedCallback() {
      this.ctx = this._canvas.getContext("2d");
      this.animate = this.animate.bind(this);
      this.charFrame = 0;
      this.FPS = 60;
      this.prevTick = 0;
      this.reachedStart = 0;
      this.charFrameFactor = 2;
      this._canvas.width = 1920;
      this._canvas.height = 1080;
      this.angle = 0;
      this.gameSpeed = 2;
      this.hue = 0;
      this.BG = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: -this._canvas.height,
        x3: 0,
        y3: 2 * -this._canvas.height,
        x4: 0,
        y4: 3 * -this._canvas.height,
        width: this._canvas.width,
        height: this._canvas.height,
      };
      this.particles = [];
      this.planets = [];
      this.dotsX = [];
      this.dotsY = [];
      this.distanceX = 100;
      this.distanceY = 50;
      this.noDotsX = this._canvas.clientWidth - 25 / this.distanceX;
      this.noDotsY = this._canvas.clientHeight - 25 / this.distanceY;
      this.cornerLeft = { x: this._canvas.clientWidth - 500, y: 40 };
      this.cornerRight = { x: this._canvas.clientWidth - 150, y: 200 };
      //this.render = this.render.bind(this);
      // get the value of the "embed" attribute
      if (!this.hasAttribute("drawVisible")) {
        this.setAttribute("drawVisible", "false");
      }
      if (!this.hasAttribute("startGame")) {
        this.setAttribute("startGame", "false");
      }
      if (!this.hasAttribute("stopGame")) {
        this.setAttribute("stopGame", "false");
      }
      this._upgradeProperty("drawVisible");
      this._upgradeProperty("startGame");
      this._upgradeProperty("stopGame");
      setTimeout(() => {
        this.renderParticles();
        this.renderPlanets();
        this.handleCharacter();
        this.handleParticles();
        this.handlePlanets();
        this.animate();
      }, 0);
    }

    renderCharacter() {
      var ctx = this.ctx;
      //const min = Math.min(this._canvas.height, this._canvas.width);
      var canvas = this._canvas;
      class Character {
        constructor() {
          this.vy = 0.2;
          this.vx = 0.3;
          this.originalWidth = 288; ///413×1124 /// 414×1121 ///286×765
          this.originalHeight = 767; //516×96
          this.width = this.originalWidth / 1.5;
          this.height = this.originalHeight / 1.5;
          this.x = canvas.width / 2 + this.width;
          this.y = canvas.height / 8 + this.height * 0.15;
          this.weight = -0.3;
          this.image = new Image();
          this.image.src = "spritesheet.png";
          this.frameX = 0;
        }
        update(angle, startGame, stopGame) {
          let curve = Math.sin(angle) * 15;
          if (stopGame == "true") {
            if (this.y <= 0 - this.height) {
              this.gameSpeed = 0;
              this.y = -2000;
              this.vy = 0;
              this.vx = 0;
            } else {
              this.vy = -10;
              this.y += this.vy;
            }
            return;
          }
          /*                     if (drawVisible == 'true') {
                        this.x = canvas.width / 2 - this.width;
                        this.y = canvas.height / 2 - this.height + 20;
                        this.vx = 0;
                        this.vy = 0;
                        return
                    } */
          if (startGame == "true") {
            if (this.y > canvas.height / 6) {
              this.vy -= this.weight;
              this.y -= this.vy;
            }
            if (this.y < canvas.height / 8 + this.height * 0.15 + curve) {
              this.y = canvas.height / 8 + this.height * 0.15 + curve;
              this.vy = 0;
              this.vx = 0;
            }
          }
        }
        draw(frameX) {
          //ctx.fillStyle = 'red'
          //ctx.fillRect(this.x, this.y, this.width, this.height)
          ctx.save();
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((45 * Math.PI) / 180);
          ctx.translate(-canvas.width / 2, -canvas.height / 2);
          ctx.drawImage(
            this.image,
            frameX * this.originalWidth,
            0,
            this.originalWidth,
            this.originalHeight,
            this.x,
            this.y,
            this.width,
            this.height
          );
          ctx.restore();
        }
      }
      return { Character };
    }
    renderPlanets() {
      var c = this.ctx;
      var canvas = this._canvas;
      class Planet {
        constructor({ position, velocity }) {
          this.position = position;
          this.velocity = velocity;
          this.size = Math.random() * 2 + 3;
          this.originalWidth = 1024;
          this.originalHeight = 1024; //516×96
          this.width = this.originalWidth / 3;
          this.height = this.originalHeight / 3;
          this.image = new Image();
          this.image.src =
            "planets/planet0" +
            Math.floor(Math.random() * (19 - 1 + 1) + 1) +
            ".png";
        }
        update() {
          if (this.image) {
            this.draw();
            this.position.x += this.velocity.x;
            this.position.y += this.velocity.y;
          }
        }
        draw() {
          c.save();
          c.drawImage(
            this.image,
            this.position.x,
            this.position.y,
            this.width,
            this.height
          );
          c.restore();
        }
      }
      this.planets = [];
      for (let i = 0; i < 1; i++) {
        this.planets.push(
          new Planet({
            position: {
              x: this._mathRandom(0.4, 1) * canvas.width,
              y: -150,
            },
            velocity: {
              x: -0.5,
              y: 0.5,
            },
          })
        );
      }
    }
    renderParticles() {
      const min = Math.min(this._canvas.height, this._canvas.width);
      var c = this.ctx;
      var canvas = this._canvas;
      class Particle {
        constructor({ position, velocity, radius, color, fades }) {
          this.position = position;
          this.velocity = velocity;
          this.radius = (radius * min) / 800;
          this.color = color;
          this.opacity = 1;
          this.fades = fades;
        }

        draw() {
          c.save();
          c.globalAlpha = this.opacity;
          c.beginPath();
          c.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2);
          c.fillStyle = this.color;
          c.fill();
          c.closePath();
          c.restore();
        }

        update() {
          this.draw();
          this.position.x += this.velocity.x;
          this.position.y += this.velocity.y;

          if (this.fades) this.opacity -= 0.01;
        }
      }
      this.particles = [];
      for (let i = 0; i < 150; i++) {
        this.particles.push(
          new Particle({
            position: {
              x: Math.random() * canvas.width,
              y: Math.random() * canvas.height,
            },
            velocity: {
              x: -0.5,
              y: 0.5,
            },
            radius: Math.random() * 2,
            color: "white",
            fades: false,
          })
        );
      }
    }
    handleParticles() {
      if (this.gameSpeed <= 10) {
        this.gameSpeed += 0.005;
      }
      this.particles.forEach((particle, i) => {
        particle.velocity.y = this.gameSpeed * 0.3 + 0.5;
        particle.velocity.x = -this.gameSpeed * 0.3 - 0.5;
        particle.position.y += particle.velocity.y;
        particle.position.x += particle.velocity.x;
        if (particle.position.y - particle.radius >= this._canvas.height) {
          particle.position.x = Math.random() * this._canvas.width;
          particle.position.y = -particle.radius;
        }
        if (particle.position.x - particle.radius <= 0) {
          particle.position.x = this._canvas.width - particle.radius;
          particle.position.y = Math.random() * this._canvas.height;
        }
        if (particle.opacity <= 0) {
          setTimeout(() => {
            this.particles.splice(i, 1);
          }, 0);
        } else {
          particle.update();
        }
      });
    }
    handleCharacter() {
      const { Character } = this.renderCharacter();
      this.character = new Character();
    }
    handlePlanets() {
      this.planets.forEach((planet, i) => {
        planet.velocity.y = this.gameSpeed * 0.3 + 0.5;
        planet.velocity.x = -this.gameSpeed * 0.3 - 0.5;
        planet.position.y += planet.velocity.y;
        planet.position.x += planet.velocity.x;
        if (
          planet.position.y - planet.height >=
          this._canvas.height + planet.height
        ) {
          this.planets.splice(i, 1);
          this.renderPlanets();
        }
        if (planet.position.x <= 0 - planet.width) {
          this.planets.splice(i, 1);
          this.renderPlanets();
        }
        planet.update();
      });
    }

    animate() {
      // eslint-disable-next-line no-self-assign
      this._canvas.width = this._canvas.width;
      /*             this.ctx.fillStyle = 'black';
            this.ctx.save();
            this.ctx.clearRect(0, 0, this._canvas.width, this._canvas.height) */
      requestAnimationFrame(this.animate);
      let now = Math.round((this.FPS * Date.now()) / 1000);
      if (now == this.prevTick) return;
      this.handleParticles();
      this.handlePlanets();
      this.character.update(
        this.angle,
        this.startGame,
        this.stopGame,
        this.drawVisible
      );
      this.character.draw(this.charFrame);
      /*             this.ctx.restore() */
      if (this.charFrame < 7) {
        this.charFrame += 1;
      } else {
        this.charFrame = 0;
      }
      this.angle += 0.05;
      this.hue++;
      this.prevTick = now;
      //console.log("animation working")
    }
    _upgradeProperty(prop) {
      if (Object.prototype.hasOwnProperty.call(this, prop)) {
        let value = this[prop];
        delete this[prop];
        this[prop] = value;
      }
    }
    get startGame() {
      return this.getAttribute("startGame");
    }
    set startGame(value) {
      this.setAttribute("startGame", value);
    }
    get stopGame() {
      return this.getAttribute("stopGame");
    }
    set stopGame(value) {
      this.setAttribute("stopGame", value);
    }
    attributeChangedCallback(name, oldValue, newValue) {
      if (oldValue === newValue) {
        return;
      }
      switch (name) {
        case "startGame":
          this.setAttribute("startGame", newValue);
          break;
        case "stopGame":
          this.setAttribute("stopGame", newValue);
          break;
        case "drawVisible":
          this.setAttribute("drawVisible", newValue);
          break;
      }
    }

    _mathRandom(min, max) {
      let result = Math.random() * (max - min) + min;
      return result == 0 ? this._mathRandom(min, max) : result;
    }
  }

  window.customElements.define("app-play-board", AppPlayBoard);
})();
