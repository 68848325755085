<template>
  <div class="box-content">
    <div class="current-round">
      <div class="round-number">{{ this.$store.state.headerText }}</div>
      <div class="logo"></div>
      <div class="countdown" v-if="countdownSeconds > 5">
        TIMP RĂMAS: {{ formatCountdown(countdownSeconds) }}
      </div>
    </div>
    <div class="jackpots">
      <div class="globaljackpot" v-if="this.$store.state.globalJackpot >= 0">
        <p class="jackpot-label">JACKPOT GLOBAL</p>
        <p class="jackpot-value">{{ this.$store.state.globalJackpot }} RON</p>
      </div>
      <div class="localjackpot" v-if="this.$store.state.localJackpot >= 0">
        <p class="jackpot-label">JACKPOT LOCAL</p>
        <p class="jackpot-value">{{ this.$store.state.localJackpot }} RON</p>
      </div>
    </div>
    <div class="box-center">
      <div v-if="screenShowcase == 'last-round'" class="center-screen">
        <div class="last-round-result">
          <p class="text-result">ULTIMUL REZULTAT</p>
          <p>{{ historyResultsRounds[0]["result"] }}</p>
          <p class="round-text">
            RUNDA {{ historyResultsRounds[0]["roundNo"] }}
          </p>
        </div>
      </div>
      <div v-if="screenShowcase == 'odds-frequency'" class="center-screen">
        <div class="last-round-result-2">
          <p class="text-result">FRECVENȚA COTELOR ALESE</p>
          <Bar
            class="chart"
            :data="formatChartData()"
            :options="chartOptions"
            :styles="styles"
          />
        </div>
      </div>
      <div v-if="screenShowcase == 'help1'" class="center-screen">
        <div class="last-round-result">
          <p class="text-result">CUM SE JOACĂ?</p>
          <p
            class="round-text"
            style="width: 50%; text-align: center; margin: auto"
          >
            Alegeți cota pe care doriți sa o atingeți înainte ca racheta să
            zboare de pe ecran.
          </p>
        </div>
      </div>
      <div v-if="screenShowcase == 'help2'" class="center-screen">
        <div class="last-round-result">
          <p class="text-result">CUM SE JOACĂ?</p>
          <p
            class="round-text"
            style="width: 50%; text-align: center; margin: auto"
          >
            Dacă la finalul rundei cota aleasă este mai mică sau egală cu cea
            finală, ați câștigat.
          </p>
        </div>
      </div>
      <div class="history-results">
        <div
          v-for="result in historyResultsRounds"
          :key="result.roundNo"
          class="history-result"
        >
          <p class="result">{{ result.result }}</p>
          <p class="round">RUNDA {{ result.roundNo }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
//import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "ResultRound",
  components: { Bar },
  props: {
    historyResultsRounds: Array,
    currentRound: Number,
    countdownSeconds: Number,
  },
  data() {
    return {
      screens: ["last-round", "odds-frequency", "help1", "help2"],
      currentScreen: "last-round",
      styles: {
        backgroundColor: "transparent",
      },
      //plugins: [ChartJsPluginDataLabels],
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: true,
            ticks: {
              font: {
                size: 20,
              },
              color: "white",
            },
          },
        },
      },
    };
  },
  computed: {
    screenShowcase: {
      get() {
        let result;
        if (this.countdownSeconds > 20) {
          result = this.screens[0];
        }
        if (this.countdownSeconds >= 15 && this.countdownSeconds <= 20) {
          result = this.screens[1];
        }
        if (this.countdownSeconds >= 10 && this.countdownSeconds < 15) {
          result = this.screens[2];
        }
        if (this.countdownSeconds >= 5 && this.countdownSeconds < 10) {
          result = this.screens[3];
        }
        return result;
      },
    },
  },
  methods: {
    formatCountdown: function (countdownSeconds) {
      let minutes = parseInt(countdownSeconds / 60);
      let seconds = countdownSeconds % 60;
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes > 0 ? minutes + ":" + seconds : "0:" + seconds;
    },
    formatChartData() {
      let chartData = {
        labels: [
          "<1.20",
          "1.40",
          "1.60",
          "1.80",
          "2.00",
          "2.50",
          "3.00",
          "3.50",
          "4.00",
          "4.50",
          "5.00",
          "7.00",
          "10.00",
          "15.00",
          "20+",
          "50+",
        ],
        datasets: [
          {
            backgroundColor: [
              "rgba(255, 159, 64, 0.4)",
              "rgba(255, 205, 86, 0.4)",
              "rgba(75, 192, 192, 0.4)",
              "rgba(54, 162, 235, 0.4)",
              "rgba(153, 102, 255, 0.4)",
              "rgba(201, 203, 207, 0.4)",
              "rgba(255, 99, 132, 0.4)",
              "rgba(54, 162, 235, 0.4)",
              "rgba(255, 159, 64, 0.4)",
              "rgba(255, 205, 86, 0.4)",
              "rgba(75, 192, 192, 0.4)",
              "rgba(54, 162, 235, 0.4)",
              "rgba(153, 102, 255, 0.4)",
              "rgba(201, 203, 207, 0.4)",
              "rgba(255, 99, 132, 0.4)",
              "rgba(54, 162, 235, 0.4)",
            ],
            barThickness: 58,
            label: false,
            data: [
              this.getRandomInInterval(20, 30),
              this.getRandomInInterval(10, 25),
              this.getRandomInInterval(30, 40),
              this.getRandomInInterval(45, 60),
              this.getRandomInInterval(45, 55),
              this.getRandomInInterval(30, 40),
              this.getRandomInInterval(25, 30),
              this.getRandomInInterval(20, 25),
              this.getRandomInInterval(15, 20),
              this.getRandomInInterval(13, 15),
              this.getRandomInInterval(10, 12),
              this.getRandomInInterval(7, 10),
              this.getRandomInInterval(5, 7),
              this.getRandomInInterval(3, 5),
              this.getRandomInInterval(3, 5),
              this.getRandomInInterval(2, 5),
            ],
          },
        ],
      };
      return chartData;
    },
    getRandomInInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>

<style scoped>
.jackpot-modal {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.maintenance-modal {
  position: absolute;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.disconnect-modal {
  position: absolute;
  z-index: 10002;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}
.box-content {
  display: block;
  width: 0px;
  height: 1080px;
  width: 1920px;
  /*   background-image: url("../assets/images/background.png"); */
  background-size: 100% 100%;
  margin: auto;
}
.ballsix-header-center {
  width: 1120px;
  height: 45px;
  float: left;
}
.box-center {
  text-align: center;
  box-sizing: border-box;
  float: left;
  width: 100vw;
  height: 100vw;
  margin-left: auto;
  margin-right: auto;
}
.round-history {
  padding-left: 15px;
}
.history-results {
  position: fixed;
  display: flex;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100px;
  text-align: center;
  animation-name: history-results;
  animation-duration: 1s;
}
.history-result {
  position: relative;
  background: rgb(5, 35, 51);
  height: fit-content;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 5px;
}
.history-result p {
  width: 100%;
  font-size: 25px;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.history-result .round {
  font-size: 15px;
}
.center-screen {
  width: 100vw;
  height: 100vw;
}
.last-round-result,
.last-round-result-2 {
  position: relative;
  top: 15%;
  left: 0;
  color: white;
  width: 100vw;
  text-align: center;
  height: fit-content;
  font-size: 80px;
  font-weight: bold;
  animation-name: fadeIn;
  animation-duration: 2s;
}
.last-round-result-2 {
  top: 8%;
}
.last-round-result p {
  margin: 0px;
  padding: 15px;
}
.text-result {
  font-size: 40px;
}
.round-text {
  font-size: 55px;
}
.chart {
  margin: auto;
  width: 1000px;
  height: 1000px;
}
.localjackpot {
  position: fixed;
  top: 70px;
  right: 480px;
  color: white;
  width: 25%;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px black;
  font-size: 40px;
  background-color: black;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  border-top: 3px solid #e5078982;
  border-left: 3px solid #e5078982;
}
.globaljackpot {
  position: fixed;
  top: 70px;
  left: 480px;
  width: 25%;
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px black;
  font-size: 40px;
  background-color: black;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  border-top: 3px solid #e5078982;
  border-right: 3px solid #e5078982;
}
.jackpot-label {
  padding: 0;
  margin: 0;
  font-size: 20px;
}
.jackpot-value {
  padding: 0;
  margin: 0;
  color: goldenrod;
}
</style>
