var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-content" }, [
    _c("div", { staticClass: "box-center" }, [
      this.screenIndex == 0
        ? _c("div", { staticClass: "center-screen" }, [
            _c(
              "div",
              { staticClass: "last-round-result" },
              [
                _c("p", { staticClass: "text-result" }, [
                  _vm._v("FRECVENȚA COTELOR ALESE"),
                ]),
                _c("Bar", {
                  staticClass: "chart",
                  attrs: {
                    data: _vm.formatChartData(),
                    options: _vm.chartOptions,
                    styles: _vm.styles,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      this.screenIndex == 1
        ? _c("div", { staticClass: "center-screen" }, [_vm._m(0)])
        : _vm._e(),
      this.screenIndex == 2
        ? _c("div", { staticClass: "center-screen" }, [_vm._m(1)])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "last-round-result" }, [
      _c("p", { staticClass: "text-result" }, [_vm._v("CUM SE JOACĂ?")]),
      _c(
        "p",
        {
          staticClass: "round-text",
          staticStyle: { width: "50%", "text-align": "center", margin: "auto" },
        },
        [
          _vm._v(
            "Alegeți cota pe care doriți sa o atingeți înainte ca racheta să zboare de pe ecran."
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "last-round-result" }, [
      _c("p", { staticClass: "text-result" }, [_vm._v("CUM SE JOACĂ?")]),
      _c(
        "p",
        {
          staticClass: "round-text",
          staticStyle: { width: "50%", "text-align": "center", margin: "auto" },
        },
        [
          _vm._v(
            "Dacă la finalul rundei cota aleasă este mai mică sau egală cu cea finală, ați câștigat."
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }